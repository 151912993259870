<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Employees</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/new-employee">
          <v-icon left dark>mdi-plus</v-icon>New Employee
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <!--  <v-col cols="12">
            <v-btn @click="defaultModal" color="primary" text><v-icon left>mdi-check</v-icon>Set Default</v-btn>
          </v-col> -->
        </v-row>
        <v-row dense>
          <v-col cols="10">
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Search"
              single-line
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              @item-selected="clickedItem"
              v-model="selectedEmployees"
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="employees"
              :search="search"
              item-key="id"
              :single-select="singleSelect"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.actions="{ item }">
                <v-btn color="primary" text :to="`/view-employee/${item.empID}`">
                  <v-icon class="mdi mdi-pencil"></v-icon>
                </v-btn>
                <v-btn color="primary" text :to="`/employees/assignments/${item.empID}`">
                  <v-icon class="mdi mdi-clipboard-text"></v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- start of dialog -->
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <!--  <v-toolbar color="accent" dark>
              <v-toolbar-title>Set Default  Employee</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.native="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar> -->

            <!-- <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    outlined
                    label="Name"
                    filled
                    readonly
                    v-model="selectedEmployee.SlpName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn color="accent" @click="save" :loading="loader"
                    >Ok</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text> -->
          </v-card>
        </v-dialog>
        <!-- end of dialog -->

        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    employees: [],
    loader: false,
    dialog: false,
    singleSelect: true,
    selectedEmployees: [],
    selectedEmployee: {},
    headers: [
      { text: "Id", value: "id" },
      { text: "Employee Code", value: "empID" },
      { text: "First Name", value: "firstName" },
      { text: "Last Name", value: "lastName" },
      { text: "Department", value: "department.Name" },
      { text: "Job Title", value: "jobTitle" },
      { text: "Created On", value: "created_at" },
      { text: "Actions", value: "actions" },
    ],
  }),
  methods: {
    clickedItem($event) {
      this.selectedEmployee = $event.item;
    },
    defaultModal() {
      if (this.selectedEmployee.id === undefined) {
        this.$refs.snackbar.show("Please select sales employee", "red");
      } else {
        this.dialog = true;
      }
    },
    save() {
      const data = {
        DfltSlp: SlpId,
      };
      const SlpId = this.selectedEmployee.id;
      const url = `/employee/set_default/${SlpId}`;
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("put", { url, data })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee-master-data`)
        .then((res) => {
          self.employees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    generalSettings() {
      const self = this;
      this.$store
        .dispatch("get", `/general_settings`)
        .then((res) => {
          // get sales employee
          if (res.ResponseData.DfltSlp !== null) {
            const id = res.ResponseData.DfltSlp;
            const employeeData = self.employees.find((record) => {
              return record.id == id;
            });
            self.selectedEmployees.push(employeeData);
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getEmployees();
    this.generalSettings();
  },
};
</script>
